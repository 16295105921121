<template>
  <BlokkliProvider v-bind="node?.blokkliProps">
    <h1 class="sr-only">Bs.ch {{ $texts('homepage', 'Startseite') }}</h1>

    <HeroImage :hero-image="homeHeroImage">
      <div
        class="bg-gradient-to-t from-body/45 from-30% to-body/0 flex-1 relative pt-30 md:pt-50 lg:pt-90 xl:pt-100 pb-15 md:pb-20 xxl:pb-25"
      >
        <div class="container lg:max-w-[750px]">
          <h2
            class="md:mb-20 mb-10 text-white text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-sans font-bold drop-shadow-[0_0_3px_rgba(0,0,0,0.5)]"
          >
            {{ $texts('homePage.searchFieldLabel', 'Stichwortesuche') }}
          </h2>

          <form @submit.prevent>
            <SearchInput
              completion-type="fulltext"
              @update:model-value="goToSearchPage"
            />
          </form>
        </div>
      </div>
    </HeroImage>

    <div class="pb-60 lg:pb-70 xl:pb-90">
      <LayoutSection
        v-if="frequentlyRequestedLinks?.length"
        :title="$texts('frequentlyRequested', 'Häufig Nachgefragt')"
        padding
        border
        class="!mt-0"
      >
        <FrequentlyRequested :links="frequentlyRequestedLinks" />
      </LayoutSection>

      <LayoutSection :title="$texts('topics', 'Themen')" padding border>
        <TopicOverview />
      </LayoutSection>

      <BlokkliField
        v-if="node"
        :list="node.paragraphs"
        name="field_paragraphs"
      />
    </div>
  </BlokkliProvider>
</template>

<script lang="ts" setup>
import { definePageMeta } from '#imports'

defineOptions({
  name: 'PageHome',
})

definePageMeta({
  name: 'home',
})

const router = useRouter()

function goToSearchPage(text: string | null) {
  router.push({
    name: 'search',
    query: {
      text: text || undefined,
    },
  })
}

const { entity: node } = await useDrupalRouteQuery('page')

const { data: frequentlyRequestedLinks } = await useAsyncGraphqlQuery(
  'frequentlyRequested',
  null,
  {
    transform: (v) => v.data.globalConfig?.fieldFrequentlyRequested,
    graphqlCaching: {
      client: true,
    },
  },
)

const seed = useState('randomSeed', () => {
  return Math.floor(Math.random() * 9999)
})

const { data: homeHeroImage } = await useAsyncGraphqlQuery(
  'homeHeroImages',
  null,
  {
    transform: function (v) {
      const entity = v.data.globalConfig?.heroImages?.first?.entity
      if (!entity) {
        return null
      }

      if (!('images' in entity) || !entity.images) {
        return null
      }

      return entity.images[seed.value % entity.images.length]
    },
    graphqlCaching: {
      client: true,
    },
  },
)

useCDNHeaders((v) => v.addTags(['nuxt:page:homepage']))

await renderPageDependencies()
</script>
